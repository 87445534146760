<template>
  <pibot-state-card :type="type">
    <template #title>
      Delete profile
    </template>

    <p class="font-weight-bold">
      Removing the profile will also delete all its related resources.
    </p>

    <p class="font-weight-bold">
      Removed profiles cannot be restored!
    </p>

    <pibot-pw-confirm
      :color="type"
      depressed
      small
      :loading="loading"
      @confirmed="handleRemoval"
    >
      Delete profile

      <template #text>
        Please authorize yourself by entering your password.
      </template>
    </pibot-pw-confirm>
  </pibot-state-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'pibot-form-block-delete-profile',
  components: {
    'pibot-state-card': () => import('@/components/cards/StateCard'),
    'pibot-pw-confirm': () => import('@/components/buttons/PasswordConfirm')
  },
  data: () => ({
    type: 'error'
  }),
  computed: {
    ...mapState({
      editing: state => state.settings.editing,
      loading: state => state.settings.loading
    })
  },
  methods: {
    ...mapActions({
      tryProfileDelete: 'settings/socket_tryProfileDelete'
    }),
    handleRemoval () {
      const payload = {
        _id: this.editing._id
      }

      this.$emit('loading', true)

      this.tryProfileDelete(payload)
        .then(() => {
          this.$router.replace('/settings/users')
        })
        .catch(error => {
          this.$emit('error', error.message)
        })
        .finally(() => {
          this.$emit('loading', false)
        })
    }
  }
}
</script>
